import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, effect, inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@merchant-portal/app/core/auth.service';
import { dashboardFeature } from '@merchant-portal/app/features/dashboard/store/dashboard.reducer';
import { NavbarBannerStateService } from '@merchant-portal/app/services/navbar-banner-state.service';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { DialogComponentService } from 'projects/merchant-portal/src/app/components/dialog/dialog.component.service';
import {
  DisbursementCutOffStatusResponse
} from 'projects/merchant-portal/src/app/models/disbursement/disbursement.interface';
import {
  Bank,
  BeneficiaryResponse,
  SingleTransactionData,
  SingleTransactionResponse,
} from 'projects/merchant-portal/src/app/models/disbursement/single-transaction/single-transaction.interface';
import {
  Observable,
  Subject,
  catchError,
  filter,
  of,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { SingleTransactionService } from './services/single-transaction.service';

@Component({
  selector: 'app-single-transaction',
  templateUrl: './single-transaction.component.html',
  styleUrls: ['./single-transaction.component.scss'],
})
export class SingleTransactionComponent implements OnInit, OnDestroy {
  singleTransactionForm: FormGroup;
  isCurrentPasswordValid = false;
  errorMessage = '';
  bankList: Bank[] = [];
  private destroy$ = new Subject<void>();
  userData = inject(AuthService).getUserInfo();
  store = inject(Store);
  fee = this.store.selectSignal(dashboardFeature.selectDisbursementFee);
  configFee = this.store.selectSignal(
    dashboardFeature.selectDisbursementConfigFee,
  );
  lastAccountNumber: string = '';
  lastBankCode: string = '';
  messageService = inject(MessageService);

  constructor(
    private fb: FormBuilder,
    private dialogComponentService: DialogComponentService,
    private singleTransactionService: SingleTransactionService,
    private router: Router,
    private bannerStateService: NavbarBannerStateService,
  ) {
    this.singleTransactionForm = this.fb.group({
      beneficiaryBankCode: ['', [Validators.required]],
      beneficiaryBankName: ['', [Validators.required]],
      beneficiaryAccountNo: ['', [Validators.required]],
      beneficiaryAccountName: ['', [Validators.required]],
      amount: [0],
      remark: ['', [Validators.maxLength(20)]],
      purposeId: [''],
      referenceId: ['', [Validators.required]],
    });
    this.singleTransactionForm.get('beneficiaryAccountName')?.disable();
    effect(() => {
      const configFee = this.configFee();
      this.singleTransactionForm
        .get('amount')
        ?.setValidators([
          Validators.required,
          Validators.max(configFee.maxAmount),
          Validators.min(configFee.minAmount),
        ]);
    });
  }

  isCheckButtonDisabled() {
    return (
      this.singleTransactionForm.get('beneficiaryAccountNo')?.value ===
        this.lastAccountNumber &&
      this.singleTransactionForm.get('beneficiaryBankCode')?.value ===
        this.lastBankCode
    );
  }

  ngOnInit(): void {
    this.getListBank();
    this.checkDisbursementCutOff();
  }

  getListBank() {
    this.singleTransactionService
      .getBankList()
      .pipe(
        catchError(({ error }: HttpErrorResponse) => throwError(error)),
        takeUntil(this.destroy$),
      )
      .subscribe((res) => {
        if (res) {
          this.bankList = res.data;
        }
      });
  }

  checkAccountNumber() {
    this.lastAccountNumber = this.singleTransactionForm.get(
      'beneficiaryAccountNo',
    )?.value;
    this.lastBankCode = this.singleTransactionForm.get(
      'beneficiaryBankCode',
    )?.value;

    this.singleTransactionService
      .beneficiaryCheck({
        additionalInfo: {},
        beneficiaryAccountNo: this.singleTransactionForm.get(
          'beneficiaryAccountNo',
        )?.value,
        beneficiaryBankCode: this.singleTransactionForm.get(
          'beneficiaryBankCode',
        )?.value,
      })
      .pipe(
        catchError(({ error }: HttpErrorResponse) => {
          this.singleTransactionForm.get('beneficiaryAccountNo')?.setErrors(
            { invalid: true },
            {
              emitEvent: true,
            },
          );
          this.singleTransactionForm.patchValue({
            beneficiaryAccountName: null,
          });
          return of(error);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((res: BeneficiaryResponse) => {
        if (res) {
          this.singleTransactionForm.patchValue(
            {
              beneficiaryAccountName: res.data.beneficiaryAccountName,
              beneficiaryBankCode: res.data.beneficiaryBankCode,
              beneficiaryAccountNo: res.data.beneficiaryAccountNo,
              beneficiaryBankName: this.bankList.find(
                (bank) => bank.code === res.data.beneficiaryBankCode,
              )?.name,
              purposeId: res.data.uuid,
            },
            {
              emitEvent: true,
            },
          );
        }
      });
  }

  checkDisbursementCutOff() {
    this.singleTransactionService.checkCutOffTime().subscribe((response: DisbursementCutOffStatusResponse) => {
      if (!response || !response?.data) {
        return;
      }

      const {status, banner, time} = response.data;

      if (status != "ONGOING") {
        return;
      }
      
      this.bannerStateService.setState({
        message: banner,
        icon: 'caution-invert',
        height: '28px',
        width: '28px',
      });
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.bannerStateService.clearState();
  }

  onSubmit() {
    const transactionData = this.singleTransactionForm.getRawValue();

    const transactionConfirmationDialogConfig = {
      width: '550px',
      height: 'fit-content',
      title: 'Transaction Confirmation',
      message:
        'Make sure all information is correct before you proceed to make a transaction.',
      body: {
        ...transactionData,
        merchantName: this.userData?.merchantName,
        fee: this.fee(),
      },
    };

    this.dialogComponentService
      .openTransactionConfirmationDialog(transactionConfirmationDialogConfig)
      .onClose.pipe(
        filter((res) => !!res),
        takeUntil(this.destroy$),
        switchMap(() => this.createTransaction(transactionData)),
      )
      .subscribe();
  }

  createTransaction(payload: SingleTransactionData): Observable<any> {
    return this.singleTransactionService.postSingleTransaction(payload).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        return throwError(() =>
          this.dialogComponentService.openErrorDialog({
            width: '700px',
            height: '500px',
            body: {
              title: 'Transaction Creation Failed',
              message: errorResponse.error?.errors,
            },
          }),
        );
      }),
      takeUntil(this.destroy$),
      tap((res: SingleTransactionResponse) => {
        if (res) {
          this.openSuccessDialog();
        }
      }),
      switchMap(() => of(null)),
    );
  }

  openSuccessDialog() {
    this.messageService.add({
      key: 'message',
      data: {
        title: 'Single Transaction Created!',
        body: 'You can check the transaction status in the Disbursement History.',
      },
    });

    this.router.navigateByUrl('/disbursement/disbursement-history');
  }
}
