import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BlockLoadingComponent } from '@merchant-portal/app/components/loading/block-loading/block-loading.component';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InputTextModule } from 'primeng/inputtext';
import { ToastModule } from 'primeng/toast';
import { Observable } from 'rxjs';
import {
  DisbursementHistoryDetailFactoryService,
  DisbursementHistoryDetailState,
} from './factory/disbursement-history-detail-store-factory';
import { TransactionReasonTypePipe } from './pipe/transaction-type-reason.pipe';
import { DisbursementHistoryDetailStore } from './store/disbursement-history-detail.store';
registerLocaleData(localeFr, 'id');
@Component({
  selector: 'app-disbursement-history-detail',
  templateUrl: './disbursement-history-detail.component.html',
  styleUrls: ['./disbursement-history-detail.component.scss'],
  standalone: true,
  providers: [
    {
      provide: DisbursementHistoryDetailFactoryService,
      useClass: DisbursementHistoryDetailStore,
    },
  ],
  imports: [
    CommonModule,
    CardModule,
    InputTextModule,
    ReactiveFormsModule,
    ButtonModule,
    BlockLoadingComponent,
    DialogModule,
    ToastModule,
    BlockLoadingComponent,
    TransactionReasonTypePipe,
  ],
})
export class DisbursementHistoryDetailComponent implements OnInit {
  id = '';
  vm$: Observable<DisbursementHistoryDetailState>;
  showTooltip = false;

  constructor(
    private store: DisbursementHistoryDetailFactoryService,
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef
  ) {
    this.vm$ = this.store.vm$;
    this.id = this.dialogConfig.data?.id;
  }

  ngOnInit() {
    this.store.requestDisbursementHistory(this.id);
    this.vm$.subscribe((url) => {
      if (url.downloadUrl) {
        window.open(url.downloadUrl, '_blank');
      }
    });
  }

  cancel() {
    this.ref.close();
  }

  download() {
    this.store.downloadRecepit(this.id);
  }
}
