import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisbursementCutOffStatusResponse } from '@merchant-portal/app/models/disbursement/disbursement.interface';
import {
  BulkTransactionFilter,
  IBulkTransactionResponse,
} from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/bulk-transaction.interface';
import { EMPTY, Observable, catchError } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';
import { BulkTransactionApiFatoryService } from '../factory/bulk-transaction-api-factory.service';

@Injectable({
  providedIn: 'root',
})
export class BulkTransactionApiService extends BulkTransactionApiFatoryService {
  public backend_portal_url =
    environment.backend_portal_url + 'api/v1/disbursements/bulk/list';

  constructor(private http: HttpClient) {
    super();
  }

  getBulkTransaction(
    filter: Partial<BulkTransactionFilter>
  ): Observable<IBulkTransactionResponse> {
    let params = new HttpParams();

    Object.keys(filter).forEach((key) => {
      const currentFilter: Record<string, any> = filter;
      if (currentFilter[key])
        params = params.set(key, currentFilter[key] as any);
    });

    return this.http
      .get<IBulkTransactionResponse>(this.backend_portal_url, { params })
      .pipe(
        catchError((val) => {
          return EMPTY;
        })
      );
  }

  checkCutOffTime(): Observable<DisbursementCutOffStatusResponse> {
    const load_url = environment.backend_portal_url + 'api/v1/disbursements/cut-off-time-status';
    return this.http.get<DisbursementCutOffStatusResponse>(load_url);
  }
}
