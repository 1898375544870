import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DisbursementInfoCardComponent } from '@merchant-portal/app/components/card/disbursement-info-card/disbursement-info-card.component';
import { SvgLoaderModule } from '@merchant-portal/app/components/icon/svg-loader/svg-loader/svg-loader.module';
import { QuotaTrackerComponent } from '@merchant-portal/app/components/quota-tracker/quota-tracker.component';
import { UserAccessDirective } from '@merchant-portal/app/core/user-access/user-access-directive';
import { DefaultValuePipe } from '@merchant-portal/app/pipe/default-value.pipe';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { BeneficiaryListModule } from './beneficiary-list/beneficiary-list.module';
import { CreateTransactionModule } from './create-transaction/create-transaction.module';
import { DisbursementHistoryModule } from './disbursement-history/all-disbursement-history/disbursement-history.module';
import { DisbursementComponent } from './disbursement.component';
import { DisbursementRoutingModule } from './disbursement.routing.module';
@NgModule({
  declarations: [DisbursementComponent],
  imports: [
    CommonModule,
    DisbursementRoutingModule,
    ButtonModule,
    DisbursementHistoryModule,
    CreateTransactionModule,
    BeneficiaryListModule,
    DisbursementInfoCardComponent,
    OverlayPanelModule,
    TooltipModule,
    DefaultValuePipe,
    UserAccessDirective,
    SvgLoaderModule,
    TooltipModule,
    CalendarModule,
    FormsModule,
    QuotaTrackerComponent,
  ],
  exports: [DisbursementComponent],
})
export class DisbursementModule {}
