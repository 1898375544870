import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { TooltipModule } from 'primeng/tooltip';
import { SvgLoaderModule } from '../icon/svg-loader/svg-loader/svg-loader.module';
registerLocaleData(localeFr, 'id');

@Component({
  selector: 'app-quota-tracker',
  standalone: true,
  imports: [CommonModule, ProgressBarModule, SvgLoaderModule, TooltipModule],
  templateUrl: './quota-tracker.component.html',
  styleUrls: ['./quota-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class QuotaTrackerComponent {
  @Input({ required: false }) tooltip: string = '';
  @Input({ required: false }) title: string = '';
  @Input({ required: true }) usage: number = 0;
  @Input({ required: true }) remaining: number = 0;
  @Input({ required: true }) usageLabel: string = '';
  @Input({ required: true }) remainingLabel: string = '';
  @Input({ required: true }) currency: string = '';

  getPercentage(): number {
    return (this.remaining / (this.remaining + this.usage)) * 100;
  }
}
