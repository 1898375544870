import { Injectable } from '@angular/core';
import FingerprintJSFree from '@fingerprintjs/fingerprintjs';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { environment } from '@merchant-portal/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FingerprintService {
  async getFingerprint(tag?: any): Promise<string> {
    let result;
    try {
      const fp = await FingerprintJS.load({
        apiKey: environment.fingerprintJs.apiKey,
        region: "ap",
      });
      result = await fp.get({ tag });
    } catch(e) {
      console.error(e);
      
      const fp = await FingerprintJSFree.load();
      result = await fp.get();
    }
    
    return result.visitorId;
  }
}