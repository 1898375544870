import { Injectable } from '@angular/core';
import { BatchPreviewFactoryService } from '../factory/batch-preview-store-factory';

import { HttpErrorResponse } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
  shareReplay,
  tap,
} from 'rxjs';
import { NewBatchService } from '../../new-batch/services/new-batch.service';
import { BatchPreviewApiFatoryService } from '../factory/batch-preview-api-factory.service';

@Injectable({
  providedIn: 'root',
})
export class BatchPreviewService extends BatchPreviewFactoryService {
  private _isLoading$ = new BehaviorSubject(false);

  private currentBatchPreviewResponse$ =
    this.newBatchService.bulkPreviewSubject$.pipe(
      tap(() => this._isLoading$.next(false)),
      shareReplay(1)
    );

  override currentBatchPreviewFile$ =
    this.newBatchService.bulkPreviewFileSubject$.pipe(
      tap(() => this._isLoading$.next(false)),
      shareReplay(1)
    );

  override currentBatchPreview$ = this.currentBatchPreviewResponse$.pipe(
    map((value) => value?.data)
  );

  override postBatchPreview(req: File): Observable<any> {
    return this.batchPreviewApiFatoryService
      .postBatchPreview(req)
      .pipe(map((res) => res));
  }

  override validatePreview(req: File): Observable<any> {
    return this.batchPreviewApiFatoryService.validateBatchPreview(req).pipe(
      map((res) => res),
      catchError((err: HttpErrorResponse) => of(err))
    );
  }

  override checkCutOffTime(): Observable<any> {
    return this.batchPreviewApiFatoryService.checkCutOffTime().pipe(
      tap(() => this._isLoading$.next(false)),
      tap( response => response ),
      tap(() => this._isLoading$.next(false))
    )
  }

  constructor(
    private batchPreviewApiFatoryService: BatchPreviewApiFatoryService,
    private newBatchService: NewBatchService
  ) {
    super();
  }
}
