import { WithdrawalDestinationSelectOption } from '@merchant-portal/app/models/payment/payment.interface';

export const withdrawalDestinationType = {
  BANK: 'BANK',
  PAYOUT_BALANCE: 'PAYOUT_BALANCE',
};

export const withdrawalDestinations: WithdrawalDestinationSelectOption[] = [
  {
    name: 'Bank Account',
    code: withdrawalDestinationType.BANK,
  },
  {
    name: 'Payout Balance',
    code: withdrawalDestinationType.PAYOUT_BALANCE,
  },
];
