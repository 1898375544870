<div [ngClass]="{ 'full-height': mode === 'page' }" class="content-container">
  <p-dialog class="otpDialog" [(visible)]="displayOtpDialog" [modal]="true" [closable]="true" (onHide)="onDialogHide()"
    *ngIf="mode === 'dialog'">
    <ng-container *ngTemplateOutlet="otpContent"></ng-container>
  </p-dialog>

  <!-- Conditionally render content directly if mode is 'page' -->
  <div class="page-container" *ngIf="mode === 'page'">
    <p-card>
      <ng-container *ngTemplateOutlet="otpContent"></ng-container>
    </p-card>
  </div>

  <ng-template #otpContent>
    <div *ngIf="vm$ | async as vm">
      <div class="p-card-title">Verification Code</div>
      <div class="p-card-subtitle">
        Enter the 6-digit code we send to your email<br />
        <strong>{{ email }}</strong>
      </div>
      <div>
        <code-input #codeInput class="otp-input invalid" [isCodeHidden]="false" [codeLength]="6" [isCharsCode]="false"
          (codeChanged)="onCodeChanged($event)" [ngClass]="{
            'invalid shake': vm.errorMessage && !vm.isSuspendAccount
          }">
        </code-input>

        <p class="warning-message" [ngStyle]="{
            color: vm.errorMessage && !vm.isSuspendAccount ? 'red' : '#929292'
          }" *ngIf="vm.errorMessage">
          <span [innerHTML]="vm.errorMessage"></span>
        </p>
        <div class="p-card-subtitle">
          <ng-container *ngIf="vm.isCountdownActive">
            Not received? Send a new code in <strong>{{ vm.countdown }}</strong>
          </ng-container>
          <ng-container *ngIf="!vm.isCountdownActive && !vm.isSuspendAccount">
            Not received?
            <span class="resend" (click)="resendOtpCode()">Resend Code</span>
          </ng-container>
        </div>
      </div>
      <app-block-loading [show]="vm.isLoading"></app-block-loading>
    </div>
  </ng-template>
</div>