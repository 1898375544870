import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CUT_OFF_STATUS, DisbursementCutOffStatus } from '@merchant-portal/app/models/disbursement/disbursement.interface';
import {
  BulkTransactionFilter,
  Meta,
} from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/bulk-transaction.interface';
import { BehaviorSubject, Observable, map, mergeMap, share, tap } from 'rxjs';
import { BulkTransactionApiFatoryService } from '../factory/bulk-transaction-api-factory.service';
import { BulkTransactionFactoryService } from '../factory/bulk-transaction-store-factory';

@Injectable({
  providedIn: 'root',
})
export class BulkTransactionService extends BulkTransactionFactoryService {
  private _isLoading$ = new BehaviorSubject(false);

  override isLoading$: Observable<boolean> = this._isLoading$;

  override currentBulkTransactionFilter$: Observable<
    Partial<BulkTransactionFilter>
  > = this.activeRouter.queryParams.pipe(
    map((value) => ({
      page: value['page'],
    }))
  );

  private currentBulkTransactionResponse$ =
    this.currentBulkTransactionFilter$.pipe(
      tap(() => this._isLoading$.next(true)),
      mergeMap((value) =>
        this.bulkTransactionApiFatoryService.getBulkTransaction(value)
      ),
      tap(() => this._isLoading$.next(false)),
      share()
    );

  override currentBulkTransaction$ = this.currentBulkTransactionResponse$.pipe(
    map((value) => value.data)
  );

  override currentBulkTransactionPagination$: Observable<Meta> =
    this.currentBulkTransactionResponse$.pipe(map((value) => value.meta));

  override requestBulkTransaction(param: Partial<BulkTransactionFilter>): void {
    this.router.navigate([], {
      queryParams: {
        page: param.page,
      },
      relativeTo: this.activeRouter,
      replaceUrl: true,
    });
  }

  override checkCutOffTime(): Observable<DisbursementCutOffStatus> {
    return this.bulkTransactionApiFatoryService.checkCutOffTime().pipe(
      tap(() => this._isLoading$.next(true)),
      map((response) => {

        if (!response?.data?.status || response?.data?.status !== CUT_OFF_STATUS.ON_GOING) {
          return {} as DisbursementCutOffStatus;
        }

        return response.data;
      }),
      tap(() => this._isLoading$.next(false))
    );
  }

  constructor(
    private bulkTransactionApiFatoryService: BulkTransactionApiFatoryService,
    private activeRouter: ActivatedRoute,
    private router: Router
  ) {
    super();
  }
}
