<div id="quota-tracker">
    <p class="title"> {{ title }}
        <app-svg-loader *ngIf="tooltip !== ''" class="svg-icon" [tooltipStyleClass]="'tooltip-content'"
            [autoHide]="false" tooltipPosition="bottom" [pTooltip]="tooltip_information"
            url="helper.svg"></app-svg-loader>
    </p>

    <p-progressBar [value]="getPercentage()" [showValue]="false" />

    <div class="limitation-legend">
        <div class="legend">
            <div class="legend-color remaining-color"></div>
            <p> {{ remainingLabel }} <span> {{ remaining | currency: 'IDR' : "symbol" : "1.0-0" : "id" }} </span>
            </p>
        </div>
        <div class="legend">
            <div class="legend-color allocation-color"></div>
            <p> {{ usageLabel }} <span> {{ usage | currency: 'IDR' : "symbol" : "1.0-0" : "id" }} </span>
            </p>
        </div>
    </div>
</div>

<ng-template #tooltip_information>
    <div>
        <span class="tooltip-text" [innerHTML]="tooltip"> </span>
    </div>
</ng-template>