import { CommonModule, registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component } from '@angular/core';
import { withdrawalDestinationType } from '@merchant-portal/app/features/dashboard/pages/payment/constant/withdrawal-destination.constant';
import { WithdrawalConfirmation } from '@merchant-portal/app/models/payment/payment.interface';
import { DividerModule } from 'primeng/divider';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

registerLocaleData(localeFr, 'id');

@Component({
  selector: 'app-withdrawal-confirmation-dialog',
  templateUrl: './withdrawal-confirmation-dialog.component.html',
  styleUrls: ['./withdrawal-confirmation-dialog.component.scss'],
  providers: [DialogService],
  standalone: true,
  imports: [CommonModule, DividerModule],
})
export class WithdrawalConfirmationDialogComponent {
  title = '';
  subTitle = '';
  content: WithdrawalConfirmation;
  isBankAccountWithdrawal = false;

  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    this.title = this.dialogConfig.data?.title;
    this.subTitle = this.dialogConfig.data?.message;
    this.content = this.dialogConfig.data?.content;
    this.isBankAccountWithdrawal =
      this.content.destination?.code === withdrawalDestinationType.BANK;
  }

  confirm() {
    this.ref.close(this.content);
  }

  cancel() {
    this.ref.close();
  }
}
