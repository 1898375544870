import { createReducer, on } from '@ngrx/store';
import { loadAppVersionFailure, loadAppVersionSuccess } from './app.actions';
import { AppState } from './app.state';

export const appInitialState: AppState = {
  app: {
    appVersion: '',
    error: '',
  },
};

export const appReducer = createReducer(
  appInitialState,
  on(loadAppVersionSuccess, (state, { appVersion }) => ({
    ...state,
    appVersion,
    error: '',
  })),
  on(loadAppVersionFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);
