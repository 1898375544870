import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/id';
import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AvailableBalance,
  BalanceData,
  BalanceResponse,
} from '@merchant-portal/app/models/transaction-history/transaction-history.interface';
import { environment } from '@merchant-portal/environments/environment';
import { DialogComponentService } from 'projects/merchant-portal/src/app/components/dialog/dialog.component.service';
import {
  DisbursementInsightFilter,
  DisbursementLimit,
  DisbursementResponse,
  DisbursementSummary,
  IPaymentResponse,
  PaymentOption,
  TransactionCountAndSum,
} from 'projects/merchant-portal/src/app/models/disbursement/disbursement.interface';
import { map, take } from 'rxjs';
import {
  APPROVAL_STATUS,
  TRANSACTION_STATUS,
  TRANSACTION_TYPE,
} from '../transaction-history/constant/transaction-history.constant';
import { DisbursementService } from './services/disbursement.service';
registerLocaleData(localeFr, 'id');

@Component({
  selector: 'app-disbursement',
  templateUrl: './disbursement.component.html',
  styleUrls: ['./disbursement.component.scss'],
})
export class DisbursementComponent implements OnInit {
  router = inject(Router);
  filter: Partial<DisbursementInsightFilter> = {};
  dateFilter: Date[] = [];
  minDate!: Date;
  maxDate!: Date;

  disbursementSummary: DisbursementSummary | undefined;
  disbursementBalance: BalanceData | undefined;
  paymentMethodList: {
    virtualAccount: PaymentOption[];
    bankTransfer: PaymentOption[];
    creditCard: PaymentOption[];
  } = {
    virtualAccount: [],
    bankTransfer: [],
    creditCard: [],
  };
  disbursementLimit: DisbursementLimit | null = null;

  private todayDate = new Date(new Date().setHours(0, 0, 0, 0));
  private get endDate() {
    const lastDate = new Date(new Date().setDate(this.todayDate.getDate() + 1));
    lastDate.setHours(23, 59, 59, 999);
    return lastDate;
  }

  totalWaitingForApprovalSingleTodayLink = this.router
    .createUrlTree([`/disbursement/disbursement-history`], {
      queryParams: {
        startCreatedAt: this.todayDate.toISOString(),
        endCreatedAt: this.endDate.toISOString(),
        status: APPROVAL_STATUS.WAITING,
        type: TRANSACTION_TYPE.SINGLE,
      },
    })
    .toString();

  totalWaitingForApprovalBulkTodayLink = this.router
    .createUrlTree([`/disbursement/disbursement-history`], {
      queryParams: {
        startCreatedAt: this.todayDate.toISOString(),
        endCreatedAt: this.endDate.toISOString(),
        status: APPROVAL_STATUS.WAITING,
        type: TRANSACTION_TYPE.BULK,
      },
    })
    .toString();

  topUpSingleLink = this.router
    .createUrlTree([`/disbursement/approval/single-transaction-retry`])
    .toString();

  topUpBulkLink = this.router
    .createUrlTree([`/disbursement/approval/bulk-transaction-retry`])
    .toString();

  get totalBalance() {
    return (
      parseFloat(this.disbursementSummary?.availableBalance?.value ?? '0') +
      parseFloat(this.disbursementSummary?.pendingBalance?.value ?? '0')
    );
  }

  constructor(
    private dialogComponentService: DialogComponentService,
    private disbursementService: DisbursementService,
    private activeRouter: ActivatedRoute,
  ) {}

  redirectPage(action: string): string {
    let path = '';
    let params = {};
    let startDate = new Date(
      new Date(this.filter.insightStartDate ?? this.todayDate).setHours(
        0,
        0,
        0,
        0,
      ),
    ).toISOString();
    let endDate = new Date(
      new Date(this.filter.insightEndDate ?? this.endDate).setHours(
        23,
        59,
        59,
        999,
      ),
    ).toISOString();

    switch (action) {
      case 'withdrawalHistories':
        const today = new Date();
        path = '/transaction-history';
        params = {
          startDate: new Date(new Date().setDate(this.todayDate.getDate() - 30))
            .toISOString()
            .split('T')[0],
          endDate: today.toISOString().split('T')[0],
          type: TRANSACTION_TYPE.DISBURSEMENT_WITHDRAWAL,
        };
        break;
      case 'allTransaction':
        path = '/disbursement/disbursement-history';
        params = {
          startCreatedAt: startDate,
          endCreatedAt: endDate,
        };
        break;
      case 'totalSuccess':
        path = '/disbursement/disbursement-history';
        params = {
          startCreatedAt: startDate,
          endCreatedAt: endDate,
          transactionStatus: TRANSACTION_STATUS.SUCCESS,
        };
        break;
      case 'totalPending':
        path = '/disbursement/disbursement-history';
        params = {
          startCreatedAt: startDate,
          endCreatedAt: endDate,
          transactionStatus: TRANSACTION_STATUS.PENDING,
        };
        break;
      case 'totalFailed':
        path = '/disbursement/disbursement-history';
        params = {
          startCreatedAt: startDate,
          endCreatedAt: endDate,
          transactionStatus: TRANSACTION_STATUS.FAILED,
        };
        break;
      case 'totalApproved':
        path = '/disbursement/disbursement-history';
        params = {
          startCreatedAt: startDate,
          endCreatedAt: endDate,
          status: APPROVAL_STATUS.APPROVED,
        };
        break;
      case 'totalRejected':
        path = '/disbursement/disbursement-history';
        params = {
          startCreatedAt: startDate,
          endCreatedAt: endDate,
          status: APPROVAL_STATUS.REJECTED,
        };
        break;
    }

    return this.router
      .createUrlTree([path], {
        queryParams: params,
      })
      .toString();
  }

  ngOnInit(): void {
    this.setDefaultFilter();
    this.initFilter();
    this.getListPaymentMethods();
    this.getListDisbursementSummary();
    this.getListDisbursementBalance();
    this.getDisbursementLimitation();
  }

  showTopUpDialog() {
    if (environment.topUpSimulation) {
      this.router.navigateByUrl('/disbursement/top-up-simulation');
    } else {
      const content = [
        ...(this.paymentMethodList?.virtualAccount ?? []),
        ...(this.paymentMethodList?.bankTransfer ?? []),
      ];

      this.dialogComponentService.openTopUpDialog({
        width: '1000px',
        height: '700px',
        body: {
          title: 'Top Up Balance',
          content: [...content],
        },
      });
    }
  }

  setDefaultFilter(): void {
    this.maxDate = new Date();
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() - 31);
    this.minDate.setHours(0, 0, 0, 0);
    this.maxDate.setHours(23, 59, 59, 999);

    let startDate = this.minDate;
    let endDate = this.maxDate;
    this.dateFilter = [startDate, endDate];

    // Apply the date filter to the filter object
    this.filter.insightStartDate = startDate;
    this.filter.insightEndDate = endDate;
  }

  initFilter(): void {
    this.activeRouter.queryParams.pipe(
      map((params) => ({
        insightStartDate: new Date(params['insightStartDate']),
        insightEndDate: new Date(params['insightEndDate']),
      })),
    );
  }

  updateQueryParams(): void {
    this.router.navigate([], {
      queryParams: {
        insightStartDate: this.filter.insightStartDate?.toISOString(),
        insightEndDate: this.filter.insightEndDate?.toISOString(),
      },
      relativeTo: this.activeRouter,
      replaceUrl: true,
    });
  }

  getListDisbursementSummary() {
    this.updateQueryParams();
    this.disbursementService
      .getDisbursementSummary(this.filter)
      .pipe(take(1))
      .subscribe((res: DisbursementResponse) => {
        if (res.data) {
          this.disbursementSummary = res?.data;
        }
      });
  }

  getListDisbursementBalance() {
    const disbursementParams: AvailableBalance = { usecase: 'DISBURSEMENT' };
    this.disbursementService
      .getTotalBalance(disbursementParams)
      .pipe(take(1))
      .subscribe((res: BalanceResponse) => {
        if (res.data) {
          this.disbursementBalance = res?.data;
        }
      });
  }

  getListPaymentMethods() {
    this.disbursementService
      .getPaymentMethods('disbursement_top_up')
      .pipe(take(1))
      .subscribe((res: IPaymentResponse) => {
        if (res.data) {
          this.paymentMethodList = res?.data;
        }
      });
  }

  getDisbursementLimitation() {
    this.disbursementService
      .getDisbursementLimitation()
      .pipe(take(1))
      .subscribe((res) => {
        if (!res) return;
        this.disbursementLimit = res.data;
      });
  }

  onFilterDateChange(event: Date[]) {
    if (event[1]) {
      this.filter.insightStartDate = event[0];
      this.filter.insightEndDate = event[1];
      this.filter.insightEndDate.setHours(23, 59, 59, 999);
      this.getListDisbursementSummary();
    }
  }

  getTransactionSummary(key: keyof DisbursementSummary): {
    count: string;
    sum: string;
  } {
    // this func used to handle backward compatible
    key = this.getCompatibleKey(key);

    const transaction = this.disbursementSummary?.[
      key
    ] as TransactionCountAndSum;

    return {
      count: transaction?.count?.toString() || '0',
      sum: transaction?.sum?.value?.toString() || '0',
    };
  }

  // this func used to handle backward compatible
  // should removed on sprint 21
  getCompatibleKey(key: keyof DisbursementSummary): keyof DisbursementSummary {
    const fallbackKeys: Partial<
      Record<keyof DisbursementSummary, keyof DisbursementSummary>
    > = {
      allTransaction: 'allTodayTransaction',
      pendingTransaction: 'pendingTodayTransaction',
      failedTransaction: 'failedTodayTransaction',
      successTransaction: 'successTodayTransaction',
      rejectedTransaction: 'rejectedTodayTransaction',
      approvedTransaction: 'approvedTodayTransaction',
    };

    // when the new property not exist, use the fallback key
    // this is to handle backward compatible
    const val = this.disbursementSummary?.[key] ?? null;

    return val === null && key in fallbackKeys
      ? (fallbackKeys[key] as keyof DisbursementSummary)
      : key;
  }
}
