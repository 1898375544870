import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface NavbarBannerState {
  message?: string;
  icon?: "caution" | "caution-invert";
  height?: "24px" | "28px" | "32px";
  width?: "24px" | "28px" | "32px";
}

@Injectable({
  providedIn: 'root'
})
export class NavbarBannerStateService {

  private bannerState = new BehaviorSubject<NavbarBannerState>({
    message: '',
    icon: 'caution-invert',
    height: '24px',
    width: '24px'
  });

  state$ = this.bannerState.asObservable();

  setState(state: NavbarBannerState): void {
    this.bannerState.next(state);
  }

  clearState(): void{
    this.bannerState.next({});
  }

}
