import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IBoxedOptionSelectionDialogBody } from '../dialog.component.service';

@Component({
  selector: 'app-boxed-option-selection-dialog',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './boxed-option-selection-dialog.component.html',
  styleUrls: ['./boxed-option-selection-dialog.component.scss']
})
export class BoxedOptionSelectionDialogComponent {
  title = '';
  subTitle = '';
  selectedOption : any = '';
  content: IBoxedOptionSelectionDialogBody[];


  constructor(
    private dialogConfig: DynamicDialogConfig,
    private ref: DynamicDialogRef,
  ) {
    this.title = this.dialogConfig.data?.title;
    this.subTitle = this.dialogConfig.data?.subTitle;
    this.content = this.dialogConfig.data?.content;
  }

  selectOption(option: IBoxedOptionSelectionDialogBody) {
    this.selectedOption = option.value
  }

  confirm() {
    this.ref.close(this.selectedOption);
  }

  cancel() {
    this.ref.close();
  }
}
