import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export abstract class BatchPreviewApiFatoryService {
  abstract postBatchPreview(
    filter: Partial<any>
  ): Observable<any>;
  abstract validateBatchPreview(
    filter: Partial<any>
  ): Observable<any>;
  abstract checkCutOffTime(): Observable<any>;
}

