import { createAction, props } from '@ngrx/store';

export const loadAppVersion = createAction('[App] Load App Version');
export const loadAppVersionSuccess = createAction(
  '[App] Load App Version Success',
  props<{ appVersion: string }>(),
);
export const loadAppVersionFailure = createAction(
  '[App] Load App Version Failure',
  props<{ error: string }>(),
);
