import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarBannerStateService } from '@merchant-portal/app/services/navbar-banner-state.service';
import { PaginatorState } from 'primeng/paginator';
import {
  BatchDetailFilter,
} from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/batch-detail/batch-detail.interface';
import {
  Subject,
} from 'rxjs';
import { BatchDetailFactoryService } from './factory/batch-detail-store-factory';

@Component({
  selector: 'app-batch-detail',
  templateUrl: './batch-detail.component.html',
  styleUrls: ['./batch-detail.component.scss'],
})
export class BatchDetailComponent implements OnInit, OnDestroy {
  datas: BatchDetailFactoryService['currentBatchDetail$'];
  datasNotUploaded: BatchDetailFactoryService['currentBatchDetailNotUpload$'];
  totalRecord = this.batchDetailFactoryService.currentBatchDetailPagination$;
  ngUnSubcribe = new Subject<boolean>();
  filter: Partial<BatchDetailFilter> = {};
  isNotUploaded = false;

  constructor(
    private batchDetailFactoryService: BatchDetailFactoryService,
    private navbarBannerStateService: NavbarBannerStateService, 
    private router: Router,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.route.paramMap.subscribe(params => {
      const bulkId = params.get('id')!;
      this.filter = { bulkId: bulkId };
    });
    this.onFilterChange();
    this.datas = this.batchDetailFactoryService.currentBatchDetail$;
    this.datasNotUploaded = this.batchDetailFactoryService.currentBatchDetailNotUpload$;
  }

  ngOnInit(): void {
    this.checkDisbursementCutOffStatus();
  }

  ngOnDestroy(): void {
    this.ngUnSubcribe.complete();
    this.navbarBannerStateService.clearState();
  }

  onPageChange(event: PaginatorState) {
    if (event.page) {
      this.batchDetailFactoryService.requestBatchDetail({
        ...this.filter,
        page: event.page + 1,
      });
    }
  }

  createBatch() {
    this.router.navigateByUrl(
      'disbursement/create-transaction/batch-detail/new-batch'
    );
  }

  onClose() {
    this.location.back();
  }

  onFilterChange() {
    this.batchDetailFactoryService.requestBatchDetail({
      ...this.filter,
      page: 0,
    });
  }

  onFilterChangeNotUploaded() {
    this.batchDetailFactoryService.requestBatchDetail({
      ...this.filter,
    });
  }

  checkStatus() {
    if (this.isNotUploaded) {
      this.onFilterChangeNotUploaded()
    } else {
      this.onFilterChange()
    }
  }

  checkDisbursementCutOffStatus() { 
    this.batchDetailFactoryService.checkDisbursementCutOffStatus().subscribe((res) => {
      const replaceStr = "00:00 - 04:59 (UTC +7) will be processed at 05:00 (UTC +7)."
      this.navbarBannerStateService.setState({
        message: res?.banner?.replace(replaceStr, `<b>${replaceStr}</b>`),
        height: '28px',
        width: '28px',
        icon: 'caution-invert',
      });
    });
  }
}
