import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { LoadingService } from '@merchant-portal/app/components/loading/services/loading.service';
import { DisbursementCutOffStatus } from '@merchant-portal/app/models/disbursement/disbursement.interface';
import { NavbarBannerStateService } from '@merchant-portal/app/services/navbar-banner-state.service';
import { PaginatorState } from 'primeng/paginator';
import { BulkTransactionFilter } from 'projects/merchant-portal/src/app/models/disbursement/bulk-transaction/bulk-transaction.interface';
import { Subject } from 'rxjs';
import { BulkTransactionFactoryService } from './factory/bulk-transaction-store-factory';

@Component({
  selector: 'app-bulk-transaction',
  templateUrl: './bulk-transaction.component.html',
  styleUrls: ['./bulk-transaction.component.scss'],
})
export class BulkTransactionComponent implements OnInit, OnDestroy {
  datas: BulkTransactionFactoryService['currentBulkTransaction$'];
  totalRecord =
    this.bulkTransactionFactoryService.currentBulkTransactionPagination$;
  status = [];
  types = [];
  dateFilter: Date[] = [];
  createdDate: any;
  selectedType = '';
  selectedStatus = '';
  ngUnSubcribe = new Subject<boolean>();
  filter: Partial<BulkTransactionFilter> = {};

  constructor(
    private bulkTransactionFactoryService: BulkTransactionFactoryService,
    private router: Router,
    private loadingService: LoadingService,
    private bannerStateService: NavbarBannerStateService
  ) {
    bulkTransactionFactoryService.isLoading$
    .pipe(takeUntilDestroyed())
    .subscribe((isLoading) => {
      if (isLoading) {
        this.loadingService.show();
      } else {
        this.loadingService.hide();
      }
    });
    this.datas = this.bulkTransactionFactoryService.currentBulkTransaction$;
  }

  ngOnInit(): void {
    this.checkDisbursementCutOff();
  }

  ngOnDestroy(): void {
    this.ngUnSubcribe.complete();
    this.bannerStateService.clearState();
  }

  onPageChange(event: PaginatorState) {
    if (event.page != undefined) {
      this.bulkTransactionFactoryService.requestBulkTransaction({
        ...this.filter,
        page: event.page + 1,
      });
    }
  }

  createBatch() {
    this.router.navigateByUrl(
      'disbursement/create-transaction/bulk-transaction/new-batch'
    );
  }

  getLastSegment(uuid: string): string {
    const segments = uuid.split('-');
    return segments[segments.length - 1];
  }

    checkDisbursementCutOff() {
      this.bulkTransactionFactoryService.checkCutOffTime().subscribe((response: DisbursementCutOffStatus) => {
        const replaceStr = "00:00 - 04:59 (UTC +7) will be processed at 05:00 (UTC +7)."
        this.bannerStateService.setState({
          message: response?.banner?.replace(replaceStr, `<b>${replaceStr}</b>`),
          icon: 'caution-invert',
          height: '28px',
          width: '28px',
        });
      })
    }
}
