<p-toast position="top-center" key="message">
    <ng-template let-message pTemplate="message" let-closeFn="closeFn">
        <div class="toast-container">
            <section>
                <span *ngIf="message.data?.title" class="toast-title">
                    {{ message.data.title }}
                </span>
                <br *ngIf="message.data?.title" />
                <span class="toast-message" [innerHTML]="message.data.body"></span>
            </section>
        </div>
    </ng-template>
</p-toast>
<app-notification></app-notification>
<router-outlet></router-outlet>