import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  computed,
  effect,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  EventType,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { NavbarBannerState, NavbarBannerStateService } from '@merchant-portal/app/services/navbar-banner-state.service';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { AuthService } from '../../core/auth.service';
import { IUserInfo } from '../../models/login/login.interface';
import { DashboardAction } from './store/dashboard.action';
import { dashboardFeature } from './store/dashboard.reducer';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  currentRoute = signal('');
  unsetWrapperRoute = [
    '/dashboard',
    '/disbursement/create-transaction/bulk-transaction/new-batch/preview',
    '/setting/team-members/create',
    '/setting/roles/create',
    '/disbursement/create-transaction/single-transaction',
  ];

  newContentRoute = [
    '/disbursement/approval/bulk-transaction-retry',
    '/disbursement/approval/single-transaction-retry',
    '/setting/team-members/create',
    '/setting/roles/create',
    '/transaction-history',
    '/platform',
    '/disbursement/create-transaction/single-transaction',
    '/disbursement/approval/bulk-transaction-approval',
    '/disbursement/top-up-simulation',
    '/disbursement',
    '/xb',
    '/xb/payout/history',
  ];
  globalRoute = [
    '/setting/team-members/edit',
    '/transaction-history',
    '/disbursement/create-transaction/single-transaction',
    '/disbursement/approval/bulk-transaction-approval',
    '/platform/edit',
    '/platform/create',
    '/xb/payout',
    'payment',
    '/setting/available-services',
    '/setting/deposit',
  ];
  userData: IUserInfo | null;
  bannerState : NavbarBannerState | null = null;
  store = inject(Store);
  permissionloading = this.store.selectSignal(dashboardFeature.selectLoading);
  routerLoading = this.store.selectSignal(dashboardFeature.selectRouteLoading);
  loading = computed(() => {
    return this.permissionloading() || this.routerLoading();
  });
  forbiddenPage = this.store.selectSignal(
    dashboardFeature.selectAccessForbiddenPage,
  );

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private location: Location,
    private bannerStateService: NavbarBannerStateService,
  ) {
    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationStart),
      )
      .subscribe((event: any) => {
        this.store.dispatch(
          DashboardAction.setPreviousUrl({ url: this.location.path() }),
        );
      });
    router.events.pipe(takeUntilDestroyed()).subscribe((event) => {
      if (event.type === EventType.NavigationStart) {
        this.store.dispatch(DashboardAction.requestRoute());
        DashboardAction.setPreviousUrl({ url: this.location.path() });
      }
      if (
        [
          EventType.NavigationEnd,
          EventType.NavigationCancel,
          EventType.NavigationSkipped,
        ].includes(event.type)
      ) {
        this.store.dispatch(
          DashboardAction.requestRouteSuccess({
            url: [],
          }),
        );
      }
    });
    this.userData = this.getUserData();
    effect(() => {
      this.globalRoute.forEach((route) => {
        if (this.currentRoute().includes(route)) {
          this.newContentRoute.push(this.currentRoute());
          this.unsetWrapperRoute.push(this.currentRoute());
        }
      });
    });
  }

  ngOnInit(): void {
    this.currentRoute.set(this.router.url);

    this.store.dispatch(DashboardAction.getDisbursementFee());

    this.getMenuAndPermissions();
    this.bannerStateService.state$.subscribe((state) => {
      this.bannerState = state;
    });
  }

  ngAfterViewInit(): void {
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd,
        ),
      )
      .subscribe((event: NavigationEnd) => {
        this.currentRoute.set(event.url);
        this.cdr.detectChanges();
      });
  }

  getUserData() {
    return this.authService.getUserInfo();
  }

  getMenuAndPermissions() {
    return this.store.dispatch(DashboardAction.getMenuAndPermissions());
  }
}
