export const environment = {
  appName: 'merchantPortal',
  appVersion: process.env['NG_APP_VERSION'],
  backend_portal_url: 'https://api-stg.harsya.com/backend-portal/',
  mock_portal_url:
    'https://0c6f5f0a-945e-4179-bc3c-d75294286cc6.mock.pstmn.io/',
  bucket_url: 'pg-staging-bulk-disbursement',
  send_new_relic: true,
  flag: {
    is_development: true,
    show_dashboard: false,
    show_help_center: false,
    show_notfication: false,
    show_my_business: false,
    show_developer_setting: true,
    use_custom_acl: true,
    use_xb_payout: true,
  },
  new_relic: {
    init: {
      distributed_tracing: { enabled: true },
      privacy: { cookies_enabled: true },
      ajax: { deny_list: ['bam.nr-data.net'] },
    },
    loader_config: {
      accountID: '3594198',
      trustKey: '3594198',
      agentID: '1386169702',
      licenseKey: process.env['NG_APP_NEW_RELIC_LICENSE_KEY'] || '',
      applicationID: '1386169702',
    },
    info: {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: process.env['NG_APP_NEW_RELIC_LICENSE_KEY'] || '',
      applicationID: '1386169702',
      sa: 1,
    },
  },
  stomp: {
    brokerURL: 'wss://rabbitmq-wss-stg.harsya.com:15673/ws',
    login: 'merchant_portal',
    passcode: process.env['NG_APP_STOMP_PASSCODE'] || '',
  },
  sharedSecretKey: process.env['NG_APP_SHARED_SECRET_KEY'] || '',
  topUpSimulation: true,
  enableMock: false,
  fingerprintJs: {
    apiKey: 'SaRVVPai96VRY6sr9C8H',
    region: 'ap',
  },
  feature_flag_host: 'https://feature-flag.stag-v2.paper.id',
};
