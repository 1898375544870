<ng-container>
    <div class="header-wrapper">
        <h2>{{ title }}</h2>
        <span [ngStyle]="{display: !selectedOption? 'block' : 'none' }" class="sub-title" [innerHTML]="subTitle"></span>
    </div>

    <div class="content-wrapper">
        <div *ngFor="let item of content" class="option-item"
            [ngClass]="selectedOption === item.value? 'selected' : 'not-selected'" (click)="selectOption(item)">
            <p class="option-title"> {{ item.title }} </p>
            <p class="option-sub-title"> {{ item.subTitle }} </p>
        </div>
    </div>


    <div class="wrap-button">
        <button (click)="cancel()" class="pg-button invert-button">Cancel</button>
        <button (click)="confirm()" [disabled]="selectedOption === ''" class="pg-button">Next</button>
    </div>
</ng-container>