import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DisbursementCutOffStatusResponse } from '@merchant-portal/app/models/disbursement/disbursement.interface';
import {
  BankListResponse,
  BeneficiaryResponse,
  CheckBeneficiaryAccount,
  SingleTransactionData,
  SingleTransactionResponse,
} from 'projects/merchant-portal/src/app/models/disbursement/single-transaction/single-transaction.interface';
import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SingleTransactionService {
  private url = environment.backend_portal_url;
  constructor(private http: HttpClient) {}

  getBankList() {
    return this.http.get<BankListResponse>(this.url + `api/v1/banks`);
  }

  beneficiaryCheck(
    obj: CheckBeneficiaryAccount
  ): Observable<BeneficiaryResponse> {
    const load_url = this.url + 'api/v1/beneficiary-accounts/inquiry';
    return this.http.post<BeneficiaryResponse>(load_url, obj);
  }

  postSingleTransaction(
    obj: SingleTransactionData
  ): Observable<SingleTransactionResponse> {
    const load_url = this.url + 'api/v1/disbursements/single/create';
    return this.http.post<SingleTransactionResponse>(load_url, obj);
  }

  checkCutOffTime(): Observable<DisbursementCutOffStatusResponse> {
    const load_url = this.url + 'api/v1/disbursements/cut-off-time-status?earlyCheck=true';
    return this.http.get<DisbursementCutOffStatusResponse>(load_url);
  }
}
