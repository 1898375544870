import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppService } from '../services/app.service';
import {
  loadAppVersion,
  loadAppVersionFailure,
  loadAppVersionSuccess,
} from './app.actions';

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private appService: AppService,
  ) {}

  loadAppVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAppVersion),
      switchMap(() =>
        this.appService.getAppVersion().pipe(
          map((response) =>
            loadAppVersionSuccess({
              appVersion: response.data.appVersion.merchantPortal,
            }),
          ),
          catchError((error) =>
            of(loadAppVersionFailure({ error: error.message })),
          ),
        ),
      ),
    ),
  );
}
