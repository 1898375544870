<div>
  <div class="single-form-header">
    <h2>{{ title }}</h2>
    <span class="sub-title" [innerHTML]="subTitle"></span>
  </div>
  <div class="transaction-data-container">
    <div class="transaction-data-content-wrap">
      <div class="transaction-data-content">
        <div class="transaction-data-title">Balance Received</div>
        <div class="transaction-data-title" style="color: #2C2C2C;">
          {{ content.amount | currency : "IDR" : "symbol" : "1.0-0" : "id" }}
        </div>
      </div>
      <div class="transaction-data-content">
        <div class="label"> Destination </div>
        <div class="value">
          {{ content.destination?.name }}
        </div>
      </div>

      <ng-container *ngIf="isBankAccountWithdrawal" [ngTemplateOutlet]="bank_information" />

    </div>
  </div>

  <div class="wrap-button">
    <button (click)="cancel()" class="pg-button invert-button">Cancel</button>
    <button (click)="confirm()" class="pg-button">Confirm</button>
  </div>
</div>

<ng-template #bank_information>
  <div class="transaction-data-content">
    <div class="label">Beneficiary Name</div>
    <div class="value">
      {{ content.beneficiaryAccountName }}
    </div>
  </div>

  <div class="transaction-data-content">
    <div class="label">Bank Name</div>
    <div class="value">
      {{ content.beneficiaryBankName }}
    </div>
  </div>

  <div class="transaction-data-content">
    <div class="label">Account Number</div>
    <div class="value">
      {{ content.beneficiaryAccountNo }}
    </div>
  </div>
</ng-template>