export interface PaymentOption {
  uuid: string;
  type: string;
  acquirer: string;
  name: string;
  description: string;
  logo: string;
  bankName?: string;
}

export interface IPaymentResponse {
  code: string;
  message: string;
  data: {
    virtualAccount: PaymentOption[];
    bankTransfer: PaymentOption[];
    creditCard: PaymentOption[];
  };
}

export interface ITopUpPayload {
  paymentMethodId?: string;
}

export interface PaymentMethod {
  uuid: string;
  type: string;
  acquirer: string;
  name: string;
  description: string;
  logo: string;
}

export interface TopUpResponse {
  code: string;
  message: string;
  data: {
    paymentMethod: PaymentMethod;
    referenceNumber: string;
    uuid: string;
    type: string;
    acquirer: string;
    name: string;
    description: string;
    logo: string;
    createdAt: string;
  };
}

export interface DisbursementResponse {
  code: string;
  data: DisbursementSummary;
}

export interface DisbursementSummary {
  pendingBalance: TransactionSummary;
  availableBalance: TransactionSummary;
  allTodayTransaction: TransactionCountAndSum;
  waitingTodayTransaction: TransactionCountAndSum;
  waitingTodaySingleTransaction: TransactionCountAndSum;
  waitingTodayBulkTransaction: TransactionCountAndSum;
  failedTodayTransaction: TransactionCountAndSum;
  successTodayTransaction: TransactionCountAndSum;
  waitingForTopUpTodayTransaction: TransactionCountAndSum;
  waitingForTopUpTodaySingleTransaction: TransactionCountAndSum;
  waitingForTopUpTodayBulkTransaction: TransactionCountAndSum;
  pendingTodayTransaction: TransactionCountAndSum;
  rejectedTodayTransaction: TransactionCountAndSum;
  approvedTodayTransaction: TransactionCountAndSum;
  allTransaction?: TransactionCountAndSum;
  failedTransaction?: TransactionCountAndSum;
  successTransaction?: TransactionCountAndSum;
  pendingTransaction?: TransactionCountAndSum;
  rejectedTransaction?: TransactionCountAndSum;
  approvedTransaction?: TransactionCountAndSum;
}

export interface TransactionSummary {
  currency: string;
  value: string;
}

export interface TransactionCountAndSum {
  count: number;
  sum: TransactionSummary;
}

export interface IDisbursementListParam {
  page: number;
  bulkId: string;
  status: string;
  perPage: number;
  startCreatedAt: Date;
  endCreatedAt: Date;
  type?: string;
  sort: string;
  sortBy: string;
}

export interface IDisbursementListResponse {
  code: string;
  data: IDisbursement[];
  errors: string;
  meta: Meta;
}

export interface IDisbursement {
  amount: number;
  approvedAt: string;
  approvedBy: string;
  beneficiaryAccountName: string;
  beneficiaryAccountNo: string;
  beneficiaryBankCode: string;
  beneficiaryBankName: string;
  bulkId: string;
  createdAt: string;
  createdBy: string;
  createdFrom: string;
  currency: string;
  deletedAt: string;
  fee: number;
  merchantId: string;
  processorReferenceId: string;
  purposeId: string;
  reasonDescription: string;
  reasonType: string;
  senderName: string;
  status: string;
  updatedAt: string;
  uuid: string;
  remark: string;
  referenceId?: string;
  totalAmount?: number;
  totalTrx?: number;
  checked?: boolean;
}

export interface Meta {
  page: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
}

export interface DisbursementInsightFilter {
  insightStartDate: Date;
  insightEndDate: Date;
}

export interface DisbursementLimitResponse {
  code: string;
  message: string;
  data: DisbursementLimit;
}

export interface DisbursementLimit {
  remaining: number;
  limit: number;
  processed: number;
}

export interface DisbursementCutOffStatusResponse {
  code: string;
  message: string;
  data: DisbursementCutOffStatus;
}

export interface DisbursementCutOffStatus {
  status: string;
  time: string;
  banner?: string;
}

export const CUT_OFF_STATUS = {
  ON_GOING: 'ONGOING',
  WHITELISTED: 'WHITELISTED',
  OFF_SCHEDULE: 'OFF_SCHEDULE',
  DEACTIVE: 'DEACTIVE',
}
