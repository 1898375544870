import { inject, Injectable } from '@angular/core';
import { DialogComponentService } from '@merchant-portal/app/components/dialog/dialog.component.service';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, of, switchMap, tap } from 'rxjs';
import {
  WithdrawalHistoryDetailFactoryService,
  WithdrawalHistoryDetailState,
} from '../factory/withdrawal-history-detail-store-factory';
import { WithdrawalHistoryDetailApiService } from '../services/withdrawal-history-detail.api.service';

const initialState: WithdrawalHistoryDetailState = {
  isLoading: false,
  currentWithdrawalHistory: null,
  downloadUrl: null,
  errorMessage: null,
};

@Injectable()
export class WithdrawalHistoryDetailStore
  extends ComponentStore<WithdrawalHistoryDetailState>
  implements WithdrawalHistoryDetailFactoryService
{
  constructor() {
    super(initialState);
  }

  private withdrawalHistoryDetailApiService = inject(
    WithdrawalHistoryDetailApiService,
  );
  private dialogService = inject(DialogComponentService);
  isLoading$ = this.select((state) => state.isLoading);
  currentWithdrawalHistory$ = this.select(
    (state) => state.currentWithdrawalHistory,
  );
  errorMessage$ = this.select((state) => state.errorMessage);

  vm$ = this.select((state) => state);

  readonly requestWithdrawalHistory = this.effect<string>((id$) =>
    id$.pipe(
      tap(() => this.patchState({ isLoading: true })),
      switchMap((id) =>
        this.withdrawalHistoryDetailApiService
          .getWithdrawalHistoryDetail(id)
          .pipe(
            tap({
              next: (response) => {
                this.patchState({
                  currentWithdrawalHistory: response.data,
                  isLoading: false,
                });
              },
              error: ({ error }) => {
                this.setOnlyError('Error', error.message);
              },
            }),
            catchError(({ error }) => {
              this.setOnlyError('Error', error.message);
              return of([]);
            }),
          ),
      ),
    ),
  );
  readonly setOnlyError = (title: string, subTitle: string | null) => {
    this.patchState({ errorMessage: subTitle, isLoading: false });
    return this.dialogService.openSuccessDialog({
      width: '400px',
      height: 'auto',
      closable: true,
      body: {
        closeText: 'Close',
        title: title,
        message: subTitle ?? '',
      },
    })?.onClose;
  };
}
