import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navbar-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './navbar-banner.component.html',
  styleUrls: ['./navbar-banner.component.scss']
})
export class NavbarBannerComponent {

  @Input() message?: string = "";
  @Input() icon?: "caution" | "caution-invert" = "caution-invert";
  @Input() height?: "24px" | "28px" | "32px" = "24px";
  @Input() width?: "24px" | "28px" | "32px" = "24px";

}
