import { Injectable } from '@angular/core';
import { BatchDetailFilter } from '@merchant-portal/app/models/disbursement/bulk-transaction/batch-detail/batch-detail.interface';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export abstract class BatchDetailApiFatoryService {
  abstract getBatchDetail(filter: Partial<BatchDetailFilter>): Observable<any>;
  abstract getBatchDetailNotUploaded(id: string): Observable<any>;
  abstract getDisbursementCutOffStatus(): Observable<any>;
}
