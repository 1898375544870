import { AfterViewInit, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { debounceTime, filter, Observable } from 'rxjs';
import { environment } from '../environments/environment'; // Import environment version
import { loadAppVersion } from './store/app.actions';
import { selectAppVersion } from './store/app.selectors'; // Selector to get appVersion from the store
import { AppState } from './store/app.state'; // Import AppState interface

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  appVersion$: Observable<string> | undefined;

  constructor(
    private store: Store<AppState>,
    private messageService: MessageService,
  ) {
    this.store.dispatch(loadAppVersion());
  }

  ngAfterViewInit() {
    const currentAppVersion = environment.appVersion;
    this.appVersion$ = this.store.select(selectAppVersion);
    this.appVersion$
      .pipe(
        debounceTime(500), // Wait for 500ms to stabilize state changes
        filter((version) => version !== environment.appVersion),
      )
      .subscribe({
        next: () => {
          this.messageService.add({
            key: 'message',
            data: {
              title: 'New Version Available!',
              body: `Your application version (${currentAppVersion}) is outdated. Please refresh to update the version.`,
            },
          });
        },
        error: (err) => {
          console.error('Error in app version subscription:', err);
        },
      });
  }
}
