import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

@Injectable()
export abstract class BulkTransactionApiFatoryService {
  abstract getBulkTransaction(
    filter: Partial<any>
  ): Observable<any>;
  abstract checkCutOffTime(): Observable<any>;
}
