<!-- dashboard.component.html -->
<div class="app-container">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="content" [ngClass]="{
      'new-content': newContentRoute.includes(currentRoute()),
      content: !newContentRoute.includes(currentRoute())
    }">
    <div class="static-header" #navbar>
      <div class="new-navbar">
        <app-navbar [userData]="getUserData()"></app-navbar>
      </div>
      <app-navbar-banner [message]="bannerState?.message" [icon]="bannerState?.icon" [height]="bannerState?.height"
        [width]="bannerState?.width"></app-navbar-banner>
    </div>

    <div [ngStyle]="{
        height: navbar?.offsetHeight + 'px',
        position: 'sticky',
      }"></div>
    <div [ngStyle]="{
      '--navbar-height': navbar?.offsetHeight + 'px',

    }" [ngClass]="{
        'unset-content-wrapper': unsetWrapperRoute.includes(currentRoute()),
        'content-wrapper': !unsetWrapperRoute.includes(currentRoute())
      }">
      <router-outlet *ngIf="!loading() && !forbiddenPage()"></router-outlet>
      <div *ngIf="forbiddenPage()" class="forbidden-page">
        <div class="forbidden-component col">
          <div style="height: 105px;width: 163px;">
            <app-svg-loader [url]="'forbidden-icon.svg'">
            </app-svg-loader>

          </div>

          <div class="headline-small">
            You Do Not Have Access to This Page
          </div>
          <div style="color: #7A7A7A;" class="body-large">
            Access to this page is required. Contact your admin to add this access to your account.

          </div>
        </div>

      </div>
      <app-block-loading *ngIf="loading()" [show]="loading()"></app-block-loading>
    </div>
  </div>
</div>