<div class="success-dialog">
  <div>
    <div class="title-wrap">
      <h2>{{ title }}</h2>
      <pre [innerHTML]="body"></pre>
    </div>
    <div class="wrap-button">
      <button (click)="close()" class="pg-button">{{ closeText }}</button>
    </div>
  </div>
</div>